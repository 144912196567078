<template>
  <div class="package-details">
    <div class="title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/market/group' }">拼团模板</el-breadcrumb-item>
        <el-breadcrumb-item>{{breadcrumb}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 表单数据 -->
    <div class="myform">
      <el-form :model="groupForm" :rules="rules" ref="rulForm" class="demo-ruleForm">
        <div class="form-top">
          <el-form-item label="活动名称" prop="name">
            <el-input class="form-input" size="small" v-model="groupForm.name"></el-input>
          </el-form-item>
          <div class="form-compo">
            <div class="form-compo-div">
              <el-form-item label="成团有效期" prop="hour">
                <el-input class="form-input" size="small" v-model="groupForm.hour"></el-input>
              </el-form-item>
            </div>
            <span class="fs">小时</span>
            <div class="form-compo-div">
              <el-form-item label="成团人数" prop="group_num">
                <el-input class="form-input" size="small" v-model="groupForm.group_num"></el-input>
              </el-form-item>
            </div>
            <span class="fs">人</span>
          </div>
        </div>
        <div class="form-center">
          <el-form-item label="拼团商品" prop="goods_id">
            <span>{{groupForm.goods_name}}</span>
            <el-button type="warning" size="mini" class="button-ml" @click="gotoRange">选择</el-button>
          </el-form-item>
          <div class="form-compo-title">商品规格</div>
          <div class="form-compo" v-for="(value, index) in groupForm.specification" :key="value.specification_id">
            <div class="form-compo-div">
              <el-form-item
                label="名称："
                :prop="'specification' + index"
                >
                <el-input class="form-input" size="small" v-model="value.name" disabled></el-input>
              </el-form-item>
            </div>
            <div class="form-compo-div">
              <el-form-item
                label="拼团价："
                :prop="'group_price' + index"
                >
                <el-input class="form-input" size="small" v-model="value.group_price"></el-input>
              </el-form-item>
            </div>
          </div>
        </div>

        <div class="form-button">
          <el-form-item>
            <el-button type="warning" size="mini" @click="submitForm">确定</el-button>
            <el-button size="mini" @click="resetForm">返回</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <!-- 弹窗 -->
    <el-dialog :visible.sync="dialogCode" :before-close="close">
      <el-table :data="goodsData" @selection-change="handleChangeGoods">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column property="name" label="商品名称"></el-table-column>
        <el-table-column property="goods_price" label="商品价格"></el-table-column>
        <el-table-column property="chain_name" label="所属机构"></el-table-column>
      </el-table>
      <el-button type="primary" @click="submitReleased">确定</el-button>
    </el-dialog>
  </div>
</template>

<script>
import { shopGoodsList, saveGroup, shopGroupDetails, getSpecification } from '../../../request/api'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      breadcrumb: '新增拼团活动',
      groupForm: {
        name: '',
        hour: '',
        group_num: '',
        goods_id: '',
        goods_name: '未选中',
        shop_group_id: '',
        specification: [
          {
            name: '',
            group_price: '',
            specification_id: Date.now()
          }
        ]
      },
      rules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ],
        hour: [
          { required: true, message: '请输入有效期', trigger: 'blur' }
        ],
        group_num: [
          { required: true, message: '请输入成团人数', trigger: 'blur' }
        ],
        goods_id: [
          { required: true, message: '请选择拼团课程', trigger: 'change' }
        ]
      },
      dialogCode: false,
      goodsData: [],
      query_info: {
        chain_id: '',
        limit: 999,
        page: 1
      }
    }
  },
  computed: {
    ...mapState('m_login', ['loginData'])
  },
  methods: {
    async getGoodsList () {
      const res = await shopGoodsList(this.query_info.chain_id, this.query_info.limit, this.query_info.page, '')
      if (res.status !== 200 || res.data.code !== '1000') this.$message.error(res.data.msg)
      this.goodsData = res.data.data.goodsList.map(element => {
        return {
          'shop_goods_id': element.shop_goods_id,
          'name': element.name,
          'goods_price': element.goods_price,
          'chain_name': element.chain_name
        }
      })
    },
    // 获取拼团价格
    async shopSpecification () {
      const ret = await getSpecification(this.groupForm.goods_id)
      if (ret.status !== 200 || ret.data.code !== '1000') {
        this.$message.error(ret.data.msg)
        return
      }
      this.groupForm.specification = ret.data.data
    },
    gotoRange () {
      this.getGoodsList()
      this.dialogCode = true
    },
    async submitForm () {
      this.$refs.rulForm.validate(valid => {
        if (!valid) {
          console.log('error submit!')
          return false
        }
      })
      if (
        this.groupForm.name === '' ||
        this.groupForm.hour === '' ||
        this.groupForm.group_num === '' ||
        this.groupForm.goods_id === '' ||
        this.groupForm.goods_name === '未选中'
      ) {
        this.$message.warning('请完善字段信息')
        return
      }
      this.groupForm.specification = JSON.stringify(this.groupForm.specification)

      const res = await saveGroup(this.groupForm)
      if (res.status !== 200 || res.data.code !== '1000') {
        this.$message.error(res.data.msg)
        return
      }
      this.$message.success('需发布后才能生效')
      this.$router.push({ path: '/market/group' })
    },
    resetForm () {
      this.$router.back()
    },
    // 关闭弹窗调用
    close () {
      this.groupForm.goods_id = ''
      this.groupForm.goods_name = '未选中'
      this.dialogCode = false
    },
    // 弹窗保存
    submitReleased () {
      this.dialogCode = false
      this.shopSpecification()
    },
    // 改变选中 触发
    handleChangeGoods (val) {
      if (val.length > 0) {
        this.groupForm.goods_id = (val[0].shop_goods_id)
        this.groupForm.goods_name = (val[0].name)
      }
    },
    async init () {
      if (this.groupForm.shop_group_id !== undefined) {
        this.breadcrumb = '编辑拼团活动'
        const res = await shopGroupDetails(this.groupForm.shop_group_id)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
          return
        }
        const groupData = res.data.data
        this.groupForm.name = groupData.name
        this.groupForm.hour = groupData.hour
        this.groupForm.group_num = groupData.group_num
        this.groupForm.goods_id = groupData.goods_id
        this.groupForm.goods_name = groupData.goods_name

        this.shopSpecification()
      }
    }
  },
  mounted () {
    this.init()
  },
  created () {
    this.query_info.chain_id = this.loginData.chain_id
    this.groupForm.shop_group_id = this.$route.query.shop_group_id
  }
}
</script>

<style lang="less" scoped>
.package-details {
  margin: 10px;
  height: 100%;
  .title {
    padding: 10px;
    background: #fff;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    margin-bottom: 10px;
  }
  .myform {
    height: 100%;
    .demo-ruleForm {
      height: 100%;
      .form-top {
        background: #fff;
        padding: 22px;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        .form-compo {
          display: flex;
          .form-compo-div {
            width: 220px;
            .form-input {
              width: 120px;
            }
          }
          .fs {
            font-size: 14px;
            line-height: 40px;
            margin-right: 18px;
          }
        }
        .form-input {
          width: 398px;
        }
      }
      .form-center {
        background: #fff;
        margin-top: 10px;
        padding: 22px;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        .form-compo-title {
          border-bottom-style: solid;
          border-bottom-color: #c4c4c4;
          border-bottom-width: 2px;
          width: 442px;
          margin-bottom: 8px;
          font-size: 14px;
          font-weight: 400;
        }
        .form-compo {
          display: flex;
          .form-compo-div {
            width: 228px;
            .form-input {
              width: 150px;
            }
          }

        }
        .button-ml {
          margin-left: 10px;
        }
      }
      .form-button {
        background: #fff;
        padding: 20px 0px 2px 20px;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        margin: 10px 0px;
      }
    }
  }
}
</style>